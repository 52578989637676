import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../../components/Share';
import ContactForm from '../../../components/ContactForm';
import Checkout from "../../../components/Checkout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <Row mdxType="Row">
        <Column colMd={1} colLg={1} mdxType="Column">
        </Column>
        <Column colMd={6} colLg={10} mdxType="Column">
          <p>{`Wizdough Second Entry indicator for NinjaTrader 8`}</p>
          <p>{`Alerts on two-legged pullback status. 14 price action signals: second entries, higher low, lower high, failed 2E, etc. 5 filters and preferences: reset on double top, engulfing bars, risk limit, etc. Precise counting of second entry, Mack’s technique. Flexible setup. Start a free trial now.`}</p>
        </Column>
        <br />
      </Row>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={2} colLg={2} mdxType="Column">
        <AnchorLinks mdxType="AnchorLinks">
          <AnchorLink mdxType="AnchorLink">Indicator&nbsp;overview</AnchorLink>
          <AnchorLink mdxType="AnchorLink">Pro&nbsp;Features</AnchorLink>
          <AnchorLink mdxType="AnchorLink">Change&nbsp;Log</AnchorLink>
          <AnchorLink mdxType="AnchorLink">Feedback</AnchorLink>
        </AnchorLinks>
      </Column>
      <Column colMd={2} colLg={4} mdxType="Column">
        {
          /* <div style={{backgroundColor: '#343444', width: "100%", padding: "20% 20% 20% 20%"}}> */
        }
        <div style={{
          backgroundColor: '#343444',
          width: "100%",
          padding: "1rem"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "500px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAC4jAAAuIwF4pT92AAADwUlEQVQ4y31Va4/bRBT1H+ADAi3sJn7MePx2bG8S59HEm02ySTbZ97YFFipaIRWoKkBbqiLxkT9+OON9dFtBPxx5POM5c8+9544NNwjg9XpQnQ5U2YMUAt54DG80gj+dwo0i6G/02J/PodIU0nHguu5/wvD39xE+ewZ/taqfqijgVdUN4WJRE3qTCYKzM4SXlzWp63lw5f8Q2kUCf7mCynP4h4dwwxDSshE8eQKv24PwLR424/tT+EdH8GazOmKtRJJUSvkBjCJNEOgILi7g7e7WcjSCkxOoLIPTNGHzsOD4CP56zbSU95JrQh2ZvIF+N178eI43TzeYkXjHth98IGEz0mHVx3rQRcN2oHy/JhPuLdEtpCfhqlvJQTvHi8EA3zA6hySxDl0puIKEjsT89QJ/H29QpS1YdWQKkUsCR3CdsAQsz4bpOnA4NhxORiT4lQU4ThIobrJNRsG5s4s5Rs8n6KoQ14M+trmmOB9wY6/fRreTo5qUWK4mOFtMMT4Yw1AMVcsZMl9vl0uklF6UbTx/eYqrV2tIysyYgnck3GFkumBxlePb79b45+cTXP1yjoPlPt5ebvDTb6cwdGUUtWs5Ff3413qDx39usFlOoEKfMhgtv3lHH5Z5BtFgGl7O8GhU4Pr7BdqzHuymQKgPpmOMu3Jr0h3XxJSkq8MKXzWaUIxMK9hhWqZxjDezOSbnFf54fYy4TXdI5lqKuhj6UFs5MB6aUmpJnoKngvdztwaWTMsPB1NUx3OEw5INkMMtduHyIFeI98b+2Ok66RofzqvaLg67yO0NIEkiA/+mLelLDa2yJhRkf4i7FNzP6TH96WU5suk+8jhBGKdI2NNZlKIVRsj7QwTDRxCOTR+yjT4JnxcD21EMR/Biic/OGlj7DnLhodGRcFIFJ2FD7HUQpAWMVquFTyGhN9tlF6etDJFj4rT8Er/LLbQtiYOkgZlnonK+hnVhItsbULJupY/g3I0p2bGsuqdbOkoafmVvIxk7CKMM6/EeTnhZ9L0Un199AWuesVOYozsItpsUN8/7eU3MIqh+H5Hu5SRGkAXIUoG8kyBvt5Ezv6pqQfR2YcSMIiISSpDdJsyyCXu0jYj+ultTJG7xfux2u5hUe0jCAOOIeeTV1mQalC2QVWP4ZQBDb1hZJhZNC1Fo45WzhTCx0NKHECUjvN7aYkFipLzmsrxAEQcYhRY6fhORbcJtdxD2O/CjBi/YB5Ilb5eRadMmN+96TZt2RNvo1lQsUjShdYZDFNJGntKH/QFc/j4Eu0nv+xe/Jl4lRqylywAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "chart-example",
              "title": "chart-example",
              "src": "/static/6de09ebef5b350a3bbd931a691ed22cc/36a81/se-chart.png",
              "srcSet": ["/static/6de09ebef5b350a3bbd931a691ed22cc/7fc1e/se-chart.png 288w", "/static/6de09ebef5b350a3bbd931a691ed22cc/36a81/se-chart.png 500w"],
              "sizes": "(max-width: 500px) 100vw, 500px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
      </Column>
      <Column colMd={2} colLg={4} mdxType="Column">
        <div style={{
          space: "0"
        }}>
One license per machine:
          <br />
          <Checkout id="2" lnk="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=5EBDJLXXUS7LC" mdxType="Checkout"><div style={{
              fontSize: "1.5rem"
            }}> $17 / month</div><s style={{
              color: '#999999'
            }}>&nbsp;$25 / month&nbsp;</s><br />billed annually</Checkout>
          <br />
          <br />
          <Checkout id="2" lnk="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=YW7HJR4UEWLLG" mdxType="Checkout"><div style={{
              fontSize: "1.5rem"
            }}> $20 / month</div><s style={{
              color: '#999999'
            }}>&nbsp;$30 / month&nbsp;</s><br />billed monthly</Checkout>
          <br />
          <br />
          <p>{`By downloading, buying from this website you acknowledge that  you understand and accept the following agreements:`}</p>
          <ul>
            <li parentName="ul">{`there is a `}<bold>{`No Refunds Policy`}</bold>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/terms/"
              }}>{`Terms and Conditions`}</a>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/risk/"
              }}>{`Full Risk Disclosure`}</a>{`;`}</li>
            <li parentName="ul"><a parentName="li" {...{
                "href": "https://www.wizdough.com/software/"
              }}>{`Software Agreement`}</a>{`.`}<br />
              <br />
              <br /></li>
          </ul>
          <br />
          <div style={{
            backgroundColor: '#343444',
            padding: '1rem',
            width: "100%",
            padding: "5%"
          }}>
            <p><a parentName="p" {...{
                "href": "https://drive.google.com/uc?id=13XVkd0Tgx-YXrzdEl1sSTPYZyK0lIPl_&export=download"
              }}>{`Download`}</a>{` - First time installed will work as a trial for 7 days.`}</p>
          </div>
          <br />
          <div style={{
            backgroundColor: '#343444',
            padding: '1rem',
            width: "100%",
            padding: "5%"
          }}>
            <a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/1y0vMf6l8q5UbVUpMAACGAyoACKa4GhX0B6xxvl9DNd4">Read and comment Full Documentation (Google Docs)</a>
            <br />
            <a rel="noopener noreferrer" target="_blank" href="https://docs.google.com/document/d/1y0vMf6l8q5UbVUpMAACGAyoACKa4GhX0B6xxvl9DNd4/export?format=pdf">Download Full Documentation (PDF)</a>
          </div>
        </div>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={4} colLg={7} mdxType="Column">
        <h3>{`What’s new in version 1.01`}</h3>
        <ul>
          <li parentName="ul">{`Failed SE and Survived SE`}</li>
          <li parentName="ul">{`Internal bars`}</li>
          <li parentName="ul">{`Risk check filter`}</li>
          <li parentName="ul">{`Bar quality check filter`}</li>
          <li parentName="ul">{`Double top/ double bottom reset. `}</li>
          <li parentName="ul">{`Symbol customization`}</li>
          <li parentName="ul">{`Six plots for Alerts and automated strategies. (FEL, FES, SEL, SES, HL, LH)`}</li>
          <li parentName="ul">{`Improved prediction of next symbols.`}</li>
        </ul>
      </Column>
      <Column colMd={3} colLg={3} mdxType="Column">
        <h3>{`Installation Guide`}</h3>
        <div style={{
          justifyContent: 'flex-end',
          display: "flex"
        }}>
          <br />
          <iframe id="ytplayer" type="text/html" src="https://www.youtube.com/embed/uvMaDVTYCCo?playsinline=1" frameBorder="0" allowFullScreen />
        </div>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={6} colLg={10} mdxType="Column">
        <h3>{`Pro features`}</h3>
        <p>{`Pro version can be used to teaching beginners and notify traders about a coming opportunity. It was also designed for implementation in automated strategies or algo-trading. Pro version generates special signals that can be used in your automated strategies.`}</p>
        <h3>{`Indicator Overview`}</h3>
        <div style={{
          backgroundColor: '#343444',
          padding: '1rem',
          width: "100%"
        }}>
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "33.68055555555555%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABYElEQVQoz02R227jMAxE/R9NYkvWXbLjSxoHRdOku8Bu+7JP+/+/cko7QNGHwVAURc5QlXeGHB2NbtHtA0prmkajhY0137ntXuqU0hu37QqzcQiBvu+p/v9e+Pe58Hc5YqXIeUtJjlOJ5LFwPw84yY9zoRiLMYaUPd5ZVKOkuXoMEwzDQHWSwlg8H5eR9/OR56Xnz3Xi/jIyTHInDa7LwFr32idubxNnqft1GbjOPbf7zPvUUbrE2/1GVa9TxF59aHh97siiblXxVKuHbbHjNjWaPgd8kPXImyCrGuQcoucYA9PYMZ9OVKUv5A0yJQt3nSjOnHNiTAFtLa08NsJaBmmJ7RoLlOx3Ha5Ni3GO4L00dBpna1La49KBTnaS25qL2YmKA1bVBLMn1DVR4mT3tE3D6J7IbsdRHRjVTlzo7WOqvogiH4jJEzpHljiF1cpq7xH/RBbEGJmDp/zMx4QXhV9uu87atEAC3wAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "long-chart",
              "title": "long-chart",
              "src": "/static/a2c962e45cd72f63d400b0afb510eebd/3cbba/long-chart.png",
              "srcSet": ["/static/a2c962e45cd72f63d400b0afb510eebd/7fc1e/long-chart.png 288w", "/static/a2c962e45cd72f63d400b0afb510eebd/a5df1/long-chart.png 576w", "/static/a2c962e45cd72f63d400b0afb510eebd/3cbba/long-chart.png 1152w", "/static/a2c962e45cd72f63d400b0afb510eebd/0b124/long-chart.png 1728w", "/static/a2c962e45cd72f63d400b0afb510eebd/4ea69/long-chart.png 2304w", "/static/a2c962e45cd72f63d400b0afb510eebd/66381/long-chart.png 2536w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </div>
        <p>{`For price action traders it’s very important to know when it’s time to enter. One of the crucial conditions for the right trade setting is the second entry or the end of a two-legged pullback. The second entry is a second attempt of the price to reverse during a pullback. This indicator helps you find the second entries on your chart. This indicator reflects rules concerning second entries broadcasted by Mack.`}</p>
        <div style={{
          backgroundColor: '#343444',
          padding: '1rem',
          width: "40%"
        }}>
          <img {...{
            "src": "/8018d774ee917bdf23fe7eec1c2c2126/second-entry.svg",
            "alt": "second-entry",
            "title": "second-entry"
          }}></img>
        </div>
        <p>{`Made with an idea in mind that beginners and professionals can use the indicator for their individual purposes. The indicator can be configured to show all relevant signals or it can be barely visible giving you just a hint about the second entry.`}</p>
        <p>{`As you see in the picture we make a new high and then a higher high which counts as zero (0). One (1) is the first entry signal bar and two (2) is the second entry signal bar. Unlike the other indicators, we show 1s and 2s at signal bars because it’s important to know where to place stops. You can also see H and L between 0 and 2. Those are lower high and higher low accordingly. They also often being used as signal bars in the right context.`}</p>
        <p>{`Grey symbols indicate incomplete signals. For example, grey (2) means the second entry is coming as soon as a bar will overlap the previous bar. When it will happen the two will become green or red. `}</p>
        <h3>{`Change Log`}</h3>
        <h4>{`1.01.0 2020-03-04`}</h4>
        <ul>
          <li parentName="ul">{`Failed SE and Survived SE;`}</li>
          <li parentName="ul">{`Internal bars;`}</li>
          <li parentName="ul">{`Risk check filter;`}</li>
          <li parentName="ul">{`Bar quality check filter;`}</li>
          <li parentName="ul">{`Double top/ double bottom reset;`}</li>
          <li parentName="ul">{`Symbol customization;`}</li>
          <li parentName="ul">{`Six plots for Alerts and automated strategies. (FEL, FES, SEL, SES, HL, LH);`}</li>
          <li parentName="ul">{`Improved prediction of next symbols.`}</li>
        </ul>
        <h4>{`1.00.5 2020-02-02`}</h4>
        <ul>
          <li parentName="ul">{`Pro version available. Pro features: sell/buy signals for use in strategies.`}</li>
          <li parentName="ul">{`Properties optimized. Removed doubling configurations.`}</li>
          <li parentName="ul">{`Added symbols for long and for short signals. Instead of just “2” you can use ↑ and ↓ or any other symbol (press WIN+. to open emojis).`}</li>
        </ul>
        <Share mdxType="Share" />
        <h3>{`Feedback`}</h3>
        <hr></hr>
      </Column>
    </Row>
    <br /><br />
    <Row mdxType="Row">
      <Column colMd={1} colLg={1} mdxType="Column">
      </Column>
      <Column colMd={3} colLg={6} mdxType="Column">
        <p>{`Have you got questions or feedback?`}</p>
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and our team will contact you back. `}</p>
      </Column>
      <Column colMd={3} colLg={4} mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      